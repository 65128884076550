import React, {useState} from 'react';
import {Link} from "react-router-dom";
import './NavBar.css';
import logo from '../../../src/tb logo.svg';
import {FiMenu, FiX} from "react-icons/fi";

const NavBar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleNavToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <div className='navBar'>
            <div id="nav-logo" className="logo">
                <img src={logo} alt="TunaBoop Logo"/>
            </div>
            <div className='navbar-buttons'>
                <ul className='navbar-links'>
                    <li><Link to="/">Home Page</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    {/*<li><Link to="/portfolio">Portfolio</Link></li>*/}
                    <li><Link to="/contactus">Contact Us</Link></li>
                </ul>
            </div>
        </div>
    );
}
export default NavBar;
