import React from 'react';
import image from '../../Images/20171227_143336.jpg';
import './HomePage.css'

function HomePage(){
  return(
    <article
    className='article'
    style={{ backgroundImage: `url(${image})` }}
  >
    <h1 className='header'>Welcome to the Tunaboop Family!</h1>
  </article>
      )
}

export default HomePage;
