import left from '../../Images/IMG_0286.jpg';
import middle from '../../Images/POP_5724.JPG';
import right from '../../Images/POP_0521.jpg';
import './AboutUs.css'

const AboutUs = () => (
  <div className="about-us">
    <div className="image-container">
      <img className='left' src={left} alt=""/>
      <img className='middle' src={middle} alt=""/>
      <img className='right' src={right} alt=""/>
    </div>
    <div className="about-us-text-container">
      <div className="about-us-text">
        <h3>About Us</h3>
        <p>
          Welcome to Tunaboop, where creativity meets cutting-edge technology! As a boutique software development
          firm, we pride ourselves on our ability to provide bespoke solutions for clients, taking their visions
          from concept to reality.
        </p>
        <p>
          At Tunaboop, we believe that collaboration is the key to success. We work closely with our clients to
          understand their needs, ensuring that we deliver personalized solutions that exceed expectations. Our agile
          approach allows us to adapt quickly to new challenges, providing a seamless development experience that our
          clients can trust.
        </p>
        <p>
          When you choose Tunaboop, you're not just choosing a software development firm; you're partnering with a team
          that's dedicated to bringing your vision to life. Let's create something extraordinary together.
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;