import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from './components/NavBar/NavBar';
import HomePage from './pages/HomePage/HomePage';
import AboutUsScreen from './pages/AboutUs/AboutUsScreen';
import ContactUsScreen from './pages/ContactUs/ContactUsScreen';
import Portfolio from './pages/Portfolio/Portfolio';
import Services from './pages/ServicesPage/ServicesPage';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
     <Router>
      <div className='navbar'>
        <NavBar />
      </div>
      <div className='main-page'>
        <Routes>
          <Route path='/' exact element={<HomePage/>} />
          <Route path='/about' exact element={<AboutUsScreen/>} />
          <Route path='/contactus' exact element={<ContactUsScreen/>} />
          <Route path='/portfolio' exact element={<Portfolio/>} />
          <Route path='/services' exact element={<Services/>} />
        </Routes>
        </div>
      <div className='footer'>
        <Footer />
      </div>
     </Router>
     
    </div>
  );
}

export default App;
