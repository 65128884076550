import ContactUs from "../../components/ContactUs/ContactUs";

const ContactUsScreen = () =>{
  return (
    <div>
      <ContactUs/>
    </div>
  );
}
export default ContactUsScreen;
