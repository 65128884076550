import './ContactUs.css'
import Map from '../Map/Map';

const location = {
  address: '1111 Vel R. Phillips Ave, Milwaukee, WI 53203',
  lat: 43.0451,
  lng: -87.9174,
}

const ContactUs = () => (
  <div className="about-us">
    <div className="card-container">
      <div className="card info">
        <div className='card-content'>
          <div className="text">
            <h3>Let's Connect :)</h3>
          </div>
          <div className="email"><a href="mailto:me@example.com">tunaboop@gmail.com</a></div>
          <div className="phone">414-368-0218</div>
          <div className="map">Find Us on the Google Maps</div>
        </div>
      </div>
      {/*<div className="card form">*/}
      {/*  <Map*/}
      {/*  location={location}*/}
      {/*  zoomLevel={17}/>*/}
      {/*</div>*/}
    </div>
  </div>
);

export default ContactUs;